<i18n>
ru:
  free: бесплатно
  more: Ещё
  reachedFree: добавлено бесплатно
ua:
  free: безкоштовно
  more: Ще
  reachedFree: додано безкоштовно
us:
  free: more for free
  more: You can add
  reachedFree: added for free
</i18n>

<template>
  <transition
    mode="out-in"
    name="slidedown"
    appear
  >
    <div
      v-if="extraProducts.length > 0"
      class="v-extra-product-wrapper"
    >
      <arora-carousel
        :items="extraProducts"
        :max-items="maxItems"
        show-part-of-next-slide
        navigation="top"
        snap-align="start"
      >
        <template #item="item: AdditionalProduct">
          <div
            class="v-menu-card v-h-100"
            data-test-id="menu-card"
          >
            <div class="v-nomenclature-card">
              <div class="v-item-card-extra-products v-h-100">
                <div class="v-item-card-extra-products--image">
                  <arora-nuxt-image
                    disable-lazy
                    :alt="item.Product.Name"
                    :height="130"
                    :image="item.Product.SmallImageURL"
                    :width="130"
                  />
                </div>
                <div
                  class="v-item-card-extra-products--right-part v-d-flex v-flex-column"
                >
                  <div
                    class="v-item-card-extra-products--title v-cursor-default v-mb-xs v-title"
                    v-html="sanitize(item.Product.Name)"
                  />
                  <transition
                    appear
                    mode="out-in"
                    name="fade"
                  >
                    <div
                      v-if="item.FreeCount - countProductInCart(item.Product.ID) > 0"
                      class="v-d-flex v-align-items-center v-error-color v-cursor-default"
                    >
                      <icon-old-general-gift
                        class="v-item-card-extra-products--icon v-mr-xs"
                      />
                      <span
                        class="v-mr-xxs"
                        v-html="translate('extraProducts.more')"
                      />
                      <common-quantity-measure
                        :measure="
                          item.Product.Measure !== ProductMeasureType.Undefined &&
                          item.Product.Quantity > 0
                            ? item.Product.Measure
                            : ProductMeasureType.Piece
                        "
                        :quantity="
                          item.FreeCount - countProductInCart(item.Product.ID)
                        "
                      />
                      <span
                        class="v-ml-xxs"
                        v-html="translate('extraProducts.free')"
                      />
                    </div>

                    <div
                      v-else-if="item.FreeCount > 0"
                      class="v-body-text-color-light v-cursor-default"
                    >
                      <common-quantity-measure
                        :measure="
                          item.Product.Measure !== ProductMeasureType.Undefined &&
                          item.Product.Quantity > 0
                            ? item.Product.Measure
                            : ProductMeasureType.Piece
                        "
                        :quantity="item.FreeCount"
                      />
                      <span
                        class="v-ml-xxs"
                        v-html="translate('extraProducts.reachedFree')"
                      />
                    </div>
                  </transition>
                  <div
                    class="v-item-card-extra-products--price v-d-flex v-flex-row v-justify-content-between v-align-items-baseline"
                  >
                    <div class="v-text-center v-mb-xs">
                      <transition
                        appear
                        mode="out-in"
                        name="fade"
                      >
                        <common-currency
                          v-if="
                            countProductInCart(item.Product.ID) - item.FreeCount !==
                              0 || item.FreeCount > 0
                          "
                          class="v-mr-xs"
                          :amount="
                            countProductInCart(item.Product.ID) - item.FreeCount > 0
                              ? item.Product.PriceToPay *
                                (countProductInCart(item.Product.ID) -
                                  item.FreeCount)
                              : 0
                          "
                        />
                      </transition>
                      <transition
                        appear
                        mode="out-in"
                        name="fade"
                      >
                        <common-currency
                          v-if="
                            appConfig.VueSettingsPreRun
                              .CartExtraPriceStrikethrough &&
                            item.FreeCount > 0 &&
                            item.Product.PriceToPay !== 0 &&
                            countProductInCart(item.Product.ID) <= item.FreeCount
                          "
                          class="v-item-card-extra-products--price--strikethrough"
                          strikethrough
                          :amount="item.Product.PriceToPay"
                        />
                      </transition>
                    </div>
                    <arora-stepper
                      show-minus-on-zero
                      :data-test-id="`cart-extra-products-${item.AdditionalProductId}`"
                      :disabled="clientStore.ClientState.state !== 'success'"
                      :label="translate('addToCartButton.addToCart')"
                      :on-decrement="() => decrement(item.Product)"
                      :on-increment="() => increment(item.Product)"
                      :value="countProductInCart(item.Product.ID)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </arora-carousel>
    </div>
  </transition>
</template>

<script setup lang="ts">
import type { AdditionalProduct, CartItem } from '~types/clientStore'
import type { ProductInfo } from '~types/common'

import type { GUID, HasID } from '@arora/common'

import { FirstAddType, ProductMeasureType } from '~api/consts'

const { maxItemsOverride } = defineProps<{
  maxItemsOverride?: number
}>()

type AdditionalProductForCarousel = HasID & AdditionalProduct

const clientStore = useClientStore()

const { addToCart, setItemCount } = useCartFunctionsWithDialogs()
const { sanitize, translate } = useI18nSanitized()
const appConfig = useAppConfig()

onMounted(() => {
  if (import.meta.client) {
    clientStore.initClientState()
  }
})

const extraProducts = computed<AdditionalProductForCarousel[]>(() =>
  (
    (clientStore.ClientState.data?.AdditionalProduct ??
      []) as AdditionalProductForCarousel[]
  ).map((product) => {
    return { ...product, ID: useId() as GUID }
  })
)
function productInCart(id: GUID): CartItem | undefined {
  return (clientStore.ClientState.data?.Cart?.Content ?? []).find(
    (product) => product.ProductID === id
  )
}

async function increment(product: ProductInfo): Promise<void> {
  const productInCartResult: CartItem | undefined = productInCart(product.ID)
  if (productInCartResult) {
    const count: number = productInCartResult.RealCount
    await (count === 0
      ? addToCart(
          product.ID,
          FirstAddType.Additional,
          product.GroupID,
          product.Name,
          product.Price
        )
      : setItemCount(
          productInCartResult.ID,
          count + 1,
          productInCartResult.GroupID,
          productInCartResult.Name
        ))
  } else {
    await addToCart(
      product.ID,
      FirstAddType.Additional,
      product.GroupID,
      product.Name,
      product.Price
    )
  }
}

async function decrement(product: ProductInfo): Promise<void> {
  const productInCartResult: CartItem | undefined = productInCart(product.ID)
  if (productInCartResult) {
    const count: number = productInCartResult.RealCount
    if (count !== 0) {
      await setItemCount(
        productInCartResult.ID,
        count - 1,
        productInCartResult.GroupID,
        productInCartResult.Name
      )
    }
  }
}

function countProductInCart(id: GUID): number {
  return (clientStore.ClientState.data?.Cart?.Content ?? [])
    .filter((product) => product.ProductID === id)
    .map((product) => product.Count)
    .reduce((partialSum, a) => partialSum + a, 0)
}

const maxItems = computed<number>(() => {
  return maxItemsOverride ?? appConfig.VueSettingsPreRun.CartExtraProductsMax
})
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/extend';

.v-extra-product-wrapper {
  border-radius: variables.$BorderRadius;
  padding-bottom: 10px;

  .swiper {
    @extend .v-pm-shadow;
  }

  .v-menu-card {
    height: auto;
  }

  .v-item-card-extra-products {
    display: flex;
    flex-direction: row;
    border-radius: variables.$BorderRadius;
    overflow: hidden;

    &--image {
      width: 120px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 10px;

      .v-img-fluid {
        border-radius: calc(variables.$BorderRadius - 10px);
        overflow: hidden;
      }
    }

    &--right-part {
      padding: 12px;
      width: calc(100% - 120px);
      font-size: 0.9rem;
    }

    &--title {
      font-size: 1.25rem;
      font-weight: 600;
    }

    &--icon {
      fill: none;
      height: 1.25rem;
      width: auto;
    }

    &--price {
      margin: auto 0 0;
      font-size: 1.25rem;

      &--strikethrough {
        font-size: 1.15rem;
      }
    }
  }
}
</style>
